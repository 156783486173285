.rvNS.error{
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    .icon{
        height: 180px;
        width: 180px;
        color: orange;
    }
}

.rvNS.dashboard {
    background: rgb(223, 223, 223);
}

.rvNS{
    background-color: #ebeff3;
    .MuiTypography-h1 .small, .MuiTypography-h2 .small, .MuiTypography-h3 .small, .MuiTypography-h4  .small, .MuiTypography-h5 span.small, .MuiTypography-h6 .small, .MuiTypography-h7 .small, .MuiTypography-h8 .small{
        font-size: 0.7em;
        font-weight: 200;
    }

    .appBar .MuiTypography-h5{
        color:rgba(6, 6, 6, 0.8)
    } 
    .canvas{
        width: 100%;
    }
    .canvas canvas{
       margin: auto;
    }
    .icon{
        height: 180px;
        width: 180px;
    }
    .icon.opaque{
        opacity: 0.2;
    }
    .iconDelete{
        background-color: #b71c1c;
        color: #fff,
    }
    .iconCode{
        background-color: #9e9e9e;
        color: #fff,
    }
    .iconCode:hover{
        opacity: 0.8;
        background-color: #9e9e9e;
    }

    .iconEdit{
        background-color: #2196f3;
        color: #fff,
    }
    .iconEdit:hover{
        opacity: 0.8;
        background-color: #2196f3;
    }
    .iconDelete:hover{
        opacity: 0.8;
        background-color: #b71c1c;
    }
    .iconWidget{
        color: #fff,
    }
    .iconWidget:hover{
        color: #ffe,
    }
    .footer{
        display: block;
        width: 100%;
        margin-top:calc(5% + 160px);
        line-height: 30px;
        bottom: 0;    
        font-size: 0.5em;  
        text-align: right;
    }
    .footer > div{
        padding-left: 5px;
        padding-right: 5px;
    }

    .drop-area{
        position: relative;
        display: block;
    }
    
    .file-drop {
        /* relatively position the container bc the contents are absolute */
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 150px;
      }
    
      .drop-area .label{
        position: absolute;
        top: 30%;
        font-size: 2em;
        display: block;
        width: 100%;
        text-align: center;
        color:#000;
        font-weight: 300;
    
      }
    
      .hidden{
        visibility: hidden;
      }
    
      .drop-area .label > .small{
        font-size: 0.5em;
        margin-top: 15px;
      }
    
      .drop-area .uploaderror{
        color: #ef5350;
        font-size: 0.5em;
        line-height: 30px;
        margin-top: 10px;
      }
    
      .drop-area .uploaderror span{
        margin-right: 10px;
        color: #ef5350;
        position: relative;
        top: 5px; 
     }
    
     .drop-area .uploaderror span svg{
        font-size: 1.5em;
    
    }
    
    .file-drop > .file-drop-target {
        /* basic styles */
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 2px;
        border: 1px dashed rgba(0,0,0,0.5);

        /* horizontally and vertically center all content */
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
      
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
      
        align-items: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
      
        justify-content: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
      
        align-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
      
        text-align: center;
      }
      
      .file-drop > .file-drop-target.file-drop-dragging-over-frame {
        z-index: 50;
        border: 1px dashed #2196f3;
        

      }
      
      .file-drop > .file-drop-target.file-drop-dragging-over-target {
        /* turn stuff orange when we are dragging over the target */
        color: #2196f3;
      }



}

