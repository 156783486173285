body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-danger {
  color: #d32f2f;
}

.act{
  color: darkorange;
}
.Mui-disabled > .act:hover{
  cursor:not-allowed;

}

.Mui-disabled > .act{
  color: grey;
}


.question{
  font-style: italic;
  font-weight: 500;
  color: rgb(0,0,0)
}

.clientPromptWrapper{
  background-color: rgba(255,255,255,1);
  height: 200px;
}

.clientPromptWrapper .clientPrompt{
  background-color: rgba(227, 227, 227,1);
}

.dialog .MuiDialog-paper{
  background-color: #ebeff3;
}